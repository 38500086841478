<template>
    <div class="box event" :class="{'is-open' : open}">
        <header class="box-header" @click="toggleBox">
            <h3>{{ eventData.title }}</h3>
            <div v-if="isAdmin" class="box-actions">
                        <span class="edit" @click.stop="editArticle">
                            <inline-svg :src="require('@/assets/images/ic_edit.svg')" height="12" />
                        </span>
                <span class="delete" @click.stop="deleteArticle">
                            <inline-svg :src="require('@/assets/images/ic_delete.svg')" height="12" />
                        </span>
                <ConfirmDialog ref="confirmDialog" />
            </div>
            <p v-if="eventData.tags" class="meta"><span v-for="tag in eventData.tags" class="category category--tag" :key="tag.uid">{{ tag.title }}</span></p>
            <p class="box-time box-time--day" v-if="fullDay"><span>Ganztags</span><span></span></p>

            <p class="box-time" v-else-if="sameDay"><span>{{ dateFormat(eventData.start, 'H:i') }}</span>
                <span></span> <span>{{ dateFormat(eventData.end, 'H:i') }}</span></p>
            <p class="box-time" v-else><span>{{ dateFormat(eventData.start, 'j.m.') }}</span><span></span><span>{{ dateFormat(eventData.end, 'j.m.') }}</span></p>

        </header>
        <transition name="slide-down">
            <div class="box-content" ref="content">
                <img class="box-image" v-for="(img,idx) in eventData.images"
                     :src="img.url"
                     :srcset="img.urlLarge + ' 800w'" :key="idx" alt="" />
                <div v-html="eventData.bodytext"></div>
                <Downloadlist :files="eventData.downloads" v-if="eventData.downloads.length > 1" />
                <div class="box-info">
                    <p :title="$t('organizer')" v-show="eventData.organizer">
                        <inline-svg :src="require('@/assets/images/ic_organizer.svg')" :alt="$t('organizer')" width="24" />
                        <span v-html="eventData.organizer"></span></p>
                    <p :title="$t('location')" v-show="eventData.location">
                        <inline-svg :src="require('@/assets/images/ic_location.svg')" :alt="$t('location')" width="24" />
                        <span v-html="eventData.location"></span></p>
                    <p v-if="eventData.price">
                        <inline-svg :src="require('@/assets/images/ic_euro.svg')" :alt="$t('price')" width="24" />
                        <span>{{eventData.price.toLocaleString('de',{style:'currency',currency:'EUR'})}}</span>
                    </p>
                    <p>
                        <span :title="$t('likes')" @click.stop="addLike" :data-count="eventData.likes.length" class="likes-btn">
                            <inline-svg :src="require('@/assets/images/ic_heart_border.svg')" v-if="!isLiked" alt="Teilnehmer" width="24" />
                            <inline-svg :src="require('@/assets/images/ic_heart.svg')" v-else alt="Teilnehmer" width="24" />
                        </span>
                        <span class="likes-list" v-if="likesList.length > 0">
                            <template v-for="(like,idx) in likesList" :key="idx"><template v-if="idx>0">, </template><router-link :to="'/profil/' + like.uid">{{ like.firstName }} {{ like.lastName
                                                                                                                                                              }}</router-link></template>
                            <span v-if="moreLikes > 0">&nbsp;{{ $t('and') }}&nbsp;<span @click="maxLikes=9999"
                                                                                        class="showlikes">{{ $tc('more_likes', this.moreLikes, {c: this.moreLikes}) }}</span></span>
                        </span>
                        <span class="likes-list" v-else>{{ $t('no_likes') }}</span>
                    </p>
                    <p>
                        <inline-svg :src="require('@/assets/images/ic_download.svg')" alt="iCal Herunterladen" width="24" />
                        <a :href="eventData.ical_url" target="_blank"> <span>iCal-Datei Herunterladen</span></a></p>
                    <!--                    <p>
											<button @click.stop="addLike" :class="{'signedUp': isLiked}" v-if="likeEnabled"  class="button button&#45;&#45;primary" v-html="$t( !isLiked ? 'like':'unlike') "></button>
										</p>-->
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import Downloadlist from './Downloadlist';
// import { TweenMax } from 'gsap';
import api, { ApiDefaults } from '../../utils/api_call';
import { ADD_MY_EVENT, DELETE_ARTICLE, REMOVE_MY_EVENT, UPDATE_ARTICLE } from '../../utils/store/actions/general';
import { defineAsyncComponent } from "vue";
import EventsBus from '@/utils/EventsBus';
import { mapGetters } from 'vuex';

export default {
	name: "ToggleBox",
	props: [ 'item' ],
	data() {
		return {
			eventData: this.item,
			open: true,
			maxLikes: 5
		};
	},
	components: {
		Downloadlist,
		ConfirmDialog: defineAsyncComponent(() => import('@/components/Plugins/ConfirmDialog'))

	},
	computed: {
		now() {
			return Math.ceil(new Date().getTime() / 1000);
		},
		sameDay: function () {
			let start = new Date();
			start.setTime(this.eventData.start * 1000);

			let end = new Date();
			end.setTime(this.eventData.end * 1000);

			return start.isSame(end, 'day');
		},
		fullDay: function () {
			return this.eventData.start === this.eventData.end;
		},
		likeEnabled: function () {
			return (this.eventData.start > this.now && this.eventData.likes_allowed === true);
		},
		isLiked: function () {
			return this.eventData.likes.map(( i ) => i.uid).indexOf(this.$store.getters.getProfile.uid) !== -1;
		},
		likesList: function () {
			// console.log(this.eventData.likes.map((i)=> i.firstName + ' ' + i.lastName).join(', '));
			return this.eventData.likes.slice(0, this.maxLikes);
			// return '';
		},
		moreLikes: function () {
			return this.eventData.likes.length - this.maxLikes;
		},
        ...mapGetters(['getProfile','isAdmin'])
	},
	methods: {
		editArticle: function () {
			EventsBus.trigger('editEvent',this.eventData);
		},
		async deleteArticle() {
			const ok = await this.$refs.confirmDialog.show({
				title: 'Veranstaltung löschen',
				message: 'Wollen Sie diese Veranstaltung wirklich löschen?',
				okButton: 'Ja, löschen!'
			});

			if ( ok ) {
				this.$store.dispatch(DELETE_ARTICLE, {uid: this.eventData.uid, target: 'events'});
			}
		},
		dateFormat: function ( tstamp, format ) {
			let date = new Date();
			date.setTime(tstamp * 1000);

			return date.format(format);
		},
		toggleBox: function () {
			this.open = !this.open;
		},
		addLike() {
			// if ( !this.likeEnabled ) return;
			let postData = new URLSearchParams();
			postData.append('tx_c3json_jsondatanews[controller]', 'JsonDataNews');
			postData.append('tx_c3json_jsondatanews[action]', 'toggleLike');
			postData.append('tx_c3json_jsondatanews[news]', this.eventData.uid);
			postData.append('tx_c3json_jsondatanews[user]', this.$store.getters.getProfile.uid);

			let apiParams = Object.assign({}, ApiDefaults, {
				params: {id: 6},
				method: 'post',
				data: postData
			});

			api(apiParams)
				.then(( response ) => {
					// if ( typeof response.data.newsDetail != 'undefined' ) {
					// 	this.eventData.likes = response.data.newsDetail;
					// }
					// else {
					let articleData = Object.assign({}, this.eventData);
					articleData.likes = response.data.likes;
					this.$store.commit(UPDATE_ARTICLE, {target: 'events', article: articleData});
					// }
					if ( response.data.action === 'added' ) {
						this.$store.commit(ADD_MY_EVENT, this.eventData.uid);
					}
					else {
						this.$store.commit(REMOVE_MY_EVENT, this.eventData.uid);
					}
				})
				.catch(error => console.log('Error', error));
		}
	},
	created() {
	}
};
</script>

<style scoped>

</style>
